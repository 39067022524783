<template>
  <div class="hello">
    <h1>코드 크래프트 표준기술 연구소</h1>
    <p>api : https://erebordevapi.codecraft.co.kr/api-docs/</p>
    <p><router-link to="/example01">three_example1</router-link></p>
    <p><router-link to="/example02">three_example1</router-link></p>
    <p><router-link to="/example03">empty</router-link></p>

  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data () {

    return {};
  },
  created () {},
  mounted() {},
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
